import React, {useEffect, useState} from "react";
import {
  getClassDocumentBySessionId,
  getRequiredClasses,
  getRequiredClassSessions,
  signUpForClassSession
} from "../fetchers";
import {useSelector} from "react-redux";
import {phoneNumberFormatter} from "shared/src/utils/formatters";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import { openPdfToPrint } from "shared/src/utils/downloadDocument";
import { Flexor, Spinner } from "shared/src/components";
import PageEmptyState from "./PageEmptyState";
import {
  AcademicCapIcon,
  BuildingLibraryIcon, CheckCircleIcon,
  InformationCircleIcon, PrinterIcon, UsersIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import { renderDataAttribute } from "./WorkSchedules";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";
import {Button} from "shared/src/components/ui/Button";
import {Text} from "shared/src/components/SectionHeading";

export default function RequiredClasses() {
  const selectedElectionId = useSelector((state: any) => state.election.electionId);
  const currentUser = useSelector((state: any) => state.currentPollworkerUser);
  const [availableClasses, setAvailableClasses] = useState<any[]>([]);
  const [requiredClasses, setRequiredClasses] = useState<any[]>([]);
  const [showSignupComplete, setShowSignupComplete] = useState(false);
  const [showSignupFailed, setShowSignupFailed] = useState(false);
  const [showScheduleView, setShowScheduleView] = useState(false);
  const [hasSignupError, setHasSignupError] = useState<boolean>(false);
  const [signingUp, setSigningUp] = useState<string>();
  const [loading, setLoading] = useState(false);
  const [sessionIdForDownload, setSessionIdForDownload] = useState('');

  useEffect(() => {
    if (!selectedElectionId) return;

    loadRequiredClasses();
  }, [selectedElectionId]);

  async function loadRequiredClasses() {
    setLoading(true);

    return getRequiredClasses(selectedElectionId).then((response) => {
      setRequiredClasses(response);
    }).finally(() => {
      setLoading(false);
    });
  }

  async function signUpForClass(sessionId: string) {
    setSigningUp(sessionId);
    setHasSignupError(false);
    setSessionIdForDownload(sessionId);

    signUpForClassSession({
      CustomerId: currentUser.Customer.Id,
      EVUserId: currentUser.EVUserId,
      PWClassSessionId: sessionId,
      PollwokerId: ''
    }, currentUser).then((success) => {
      if (!success) {
        setHasSignupError(true);
        return;
      }
      loadRequiredClasses();

      setHasSignupError(false);
      setShowSignupComplete(true);
      setShowScheduleView(false);
    })
    .finally(() => setSigningUp(undefined));
  }

  function openViewSchedule(sessionId: string) {
    getRequiredClassSessions(selectedElectionId, sessionId).then((response) => {
      setAvailableClasses(response);
      setShowScheduleView(true);
    });
  }

  function downloadSessionDocument(sessionId: string, evUserId: string) {
    getClassDocumentBySessionId(sessionId, evUserId).then(openPdfToPrint);
  }

  return (
    <div className="mt-2">
      <DeprecatedModal size='2xl' title='Class Schedule' open={showScheduleView} onClose={() => setShowScheduleView(false)}>
        <div className="sm:flex sm:items-center">
          <p className="mt-2 text-sm text-gray-700">
            The class list below contains the class session times that are available for the subject matter.
            Keep in mind that class size is limited, so choose your class session as soon as possible.
          </p>
        </div>
        {
          hasSignupError ? (
            <div className="rounded-md bg-red-50 p-4 mt-3">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                </div>
                <div className="ml-3 flex-1 md:flex md:justify-between items-center">
                  <h3 className="text-sm font-medium text-red-800">There was an error signing up for this class session. Please try again!</h3>
                  <p className="mt-3 text-sm md:ml-6 md:mt-0">
                    <Button variant='tertiary' onClick={() => setHasSignupError(false)} className="text-error">
                      Close
                    </Button>
                  </p>
                </div>
              </div>
            </div>
          ) : null
        }
        <ol className="divide-y divide-gray-100 border-t border-gray-300 mt-6 pt-3 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {
            !availableClasses.length ? (
                <div className='my-10'>
                  <PageEmptyState icon={AcademicCapIcon} emptyMessageHeader='No available class sessions' emptyMessageSubHeader='There are no available sessions yet for this class, be sure to check back soon.' reload={loadRequiredClasses} />
                </div>
              ) :
              availableClasses.map((klass) => {
              return (
                <li key={klass.Id} className="relative cursor-pointer flex space-x-6 py-3 hover:bg-gray-50 xl:static">
                  <div className="flex-auto text-gray-900">
                    <Flexor justify='start' className='space-x-3'>
                      <InformationCircleIcon className='h-5 w-5' />
                      <h3 className="font-semibold xl:pr-0 space-x-2 flex items-center">
                        <div>{renderDataAttribute(klass.Schedule)}</div>
                      </h3>
                    </Flexor>
                    <dl className="mt-2 flex-col hidden xl:flex-row">
                      <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5">
                          <ScreenReaderOnly>Location</ScreenReaderOnly>
                          <BuildingLibraryIcon className="h-5 w-5" aria-hidden="true" />
                        </dt>
                        <dd>{renderDataAttribute(klass.Location)}</dd>
                      </div>
                    </dl>
                    <dl className="mt-2 flex flex-col xl:flex-row">
                      <div className="mt-2 flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5" aria-hidden>
                          <UsersIcon className="h-5 w-5" aria-hidden="true" />
                        </dt>
                        <dd>
                          <ScreenReaderOnly>Available Seats</ScreenReaderOnly>
                          {renderDataAttribute(klass.SeatsAvailable)} of {renderDataAttribute(klass.ClassSize)} spots available
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div className='flex flex-col items-center justify-center'>
                    <div>
                      <Button disabled={!!signingUp} className='whitespace-nowrap' onClick={() => signUpForClass(klass.Id)}>
                        {signingUp === klass.Id ? <Spinner light/> : null}
                        <span>Sign Up</span>
                      </Button>
                    </div>
                  </div>
                </li>
              )
            })
          }
        </ol>
      </DeprecatedModal>
      <DeprecatedModal title='Congratulations!' open={showSignupComplete} onClose={() => setShowSignupComplete(false)}>
        <div className="mb-8 mt-5 rounded-md bg-green-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-green-800" aria-hidden='true'>You've been scheduled</h3>
              <div className="mt-2 text-sm text-green-700" tabIndex={-1}>
                <p>You have been scheduled for this class session.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <Button data-testid='close-success-modal' aria-label='close' className='text-sm' onClick={() => setShowSignupComplete(false)}>
            Close
          </Button>
          <Button className='text-sm flex items-center space-x-1' onClick={() => downloadSessionDocument(sessionIdForDownload, currentUser.EVUserId)}>
            <PrinterIcon className='h-4 w-4' />
            <span>Print Schedule</span>
          </Button>
        </div>
      </DeprecatedModal>
      <DeprecatedModal title='Sign Up Failed' open={showSignupFailed} onClose={() => setShowSignupFailed(false)}>
        <div className="sm:flex sm:items-center">
          <p className="mt-2 text-sm text-gray-700">
            There was an error signing up for this class session. Please try again later.
          </p>
          <div>
            <Button className='p-1.5 border-2 rounded-lg bg-ev-red text-white' onClick={() => setShowSignupFailed(false)}>
              Close
            </Button>
          </div>
        </div>
      </DeprecatedModal>

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">Required Classes</h1>
          <p className="mt-2 text-sm text-gray-700">
            This list contains the subjects in which you require training. These classes are not optional.
            Click the View Schedule button to view all of the available times. Be aware that class size is limited and
            you must reserve your seat in class as soon as possible.
          </p>
        </div>
      </div>

      {
        loading ? (
          <div className='my-20 flex flex-col justify-center items-center'>
            <Flexor className='space-x-3'>
              <Spinner />
              <span>Loading required classes...</span>
            </Flexor>
          </div>
        ) : (
          <>
            {
              !requiredClasses.length ? (
                <div className='my-10'>
                  <PageEmptyState icon={AcademicCapIcon} emptyMessageHeader='No required classes' emptyMessageSubHeader='There are no required classes yet, be sure to check back soon.' reload={loadRequiredClasses} />
                </div>
              ) : (
                <div className="-mx-4 mt-8 sm:-mx-0">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                      <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                        Class
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                        Contact
                      </th>
                      <th scope="col" className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell">
                        Phone
                      </th>
                      <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                        <ScreenReaderOnly>View Schedule</ScreenReaderOnly>
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      requiredClasses.map((requiredClass) => {
                        return (
                          <tr key={requiredClass.Id}>
                            <td
                              className="w-auto max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0">
                              {requiredClass.ClassName}
                            </td>
                            <td className="hidden px-3 py-4 text-sm text-gray-800 sm:table-cell">
                              {requiredClass.ContactName}
                            </td>
                            <td
                              className="hidden px-3 py-4 text-sm text-gray-800 sm:table-cell">
                              {phoneNumberFormatter({value: requiredClass.Phone})}
                            </td>
                            <td className='w-1/2 lg:w-auto'>
                              <Button className='ml-auto' onClick={() => openViewSchedule(requiredClass.Id)}>
                                View Schedule
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    }
                    </tbody>
                  </table>
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}
