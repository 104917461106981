import { useAppSelector } from "../hooks";
import React, { useState } from "react";
import { Link } from "wouter";
import { EasyVoteIcon } from "shared/src/components";

export default function DomainLogo({ authed = false }: { authed?: boolean }) {
  const currentCustomer = useAppSelector((state) => state.currentCustomer);
  const [showEvCheckLogo, setShowEvCheckLogo] = useState<boolean>(false)

  if (!currentCustomer?.Customer?.Id) return (<div></div>);

  return (
    <Link to='/' className="-m-1.5 p-1.5">
      <>
        {
          showEvCheckLogo ? (
            <EasyVoteIcon className='h-8 w-auto' />
          ) : (
            <>
              {
                authed ? (
                  <img
                    className='max-w-24 sm:max-w-32 max-h-16'
                    src={`${process.env.REACT_APP_DOMAIN}/images/${currentCustomer?.Customer?.Id}/logo`}
                    alt={`Logo for ${currentCustomer?.Customer?.Name}`}
                    onError={() => setShowEvCheckLogo(true)}
                    aria-hidden='true'
                  />
                ) : (
                  <img
                    className='max-w-24 sm:max-w-32 max-h-24'
                    src={`${process.env.REACT_APP_DOMAIN}/images/${currentCustomer?.Customer?.Id}/logo`}
                    alt={`Logo for ${currentCustomer?.Customer?.Name}`}
                    onError={() => setShowEvCheckLogo(true)}
                    aria-hidden='true'
                  />
                )
              }
            </>
          )
        }
      </>
    </Link>
  )
}
