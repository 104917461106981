import React, { Fragment, useMemo, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { Flexor } from "shared/src/components";
import {
  BuildingLibraryIcon,
  CalendarDaysIcon,
  MapPinIcon
} from "@heroicons/react/24/outline";
import { renderDataAttribute, getStatusLabel } from "./WorkSchedules";
import { SectionSubSubHeading } from 'shared/src/components/SectionHeading';
import DateFormatter from 'shared/src/components/DateFormatter';
import Calendar from "./Calendar";
import { WorkSchedule } from "../types/WorkSchedule";
import {ScreenReaderHidden, ScreenReaderOnly} from "shared/src/components/Accessibility";
import { Button } from "shared/src/components/ui";
import { Dialog, DialogContent } from "shared/src/components/ui/Dialog";
import WorkScheduleResponseForm from "./WorkScheduleResponseForm";

export default function WorkScheduleCalendarView({workSchedules}: {workSchedules: WorkSchedule[]}) {
  const [showWorkConfirmation, setShowWorkConfirmation] = useState<WorkSchedule>();
  const [selectedDay, setSelectedDay] = useState<Dayjs>();
  const [startDay, setStartDay] = useState<Dayjs>(dayjs());

  const filteredWorkSchedules = useMemo(() => {
    if (!selectedDay) return workSchedules;

    return workSchedules.filter(({DisplayWorkDate}) => dayjs(DisplayWorkDate).isSame(selectedDay));
  }, [workSchedules, selectedDay]);

  return (
    <div className="lg:flex border-t border-gray-200 space-x-4 pt-8 divide-x divide-gray-200">
      <div className='w-full pr-4'>
        <Flexor className='flex-none w-full items-center font-normal border-b border-gray-100 mb-5 pb-3'>
          <SectionSubSubHeading aria-label='your work schedule'>Your work schedule</SectionSubSubHeading>
          {selectedDay ? <Button variant='tertiary' onClick={() => setSelectedDay(undefined)}>Clear selected date</Button> : null}
        </Flexor>
        {
          !filteredWorkSchedules.length ? (
            <div className='h-[80%] w-full rounded-md flex flex-col justify-center items-center space-y-3'>
              <div>No work schedules for <DateFormatter dateString={selectedDay?.toISOString()} withTime={false} /></div>
            </div>
          ) : null
        }
        <ol className="divide-y divide-gray-100 text-sm leading-6 lg:col-span-7 xl:col-span-8">
          {
            filteredWorkSchedules.map((schedule) => (
              <Fragment key={schedule.Id}>
                <Dialog open={showWorkConfirmation?.Id === schedule.Id} onOpenChange={() => setShowWorkConfirmation(undefined)}>
                  <DialogContent>
                    <WorkScheduleResponseForm
                      closeModal={() => setShowWorkConfirmation(undefined)}
                      workScheduleId={schedule.Id}
                    />
                  </DialogContent>
                </Dialog>

                <li data-testid={`schedule-${schedule.Id}`} className="relative cursor-pointer flex items-center justify-between space-x-2 px-4 py-3 hover:bg-gray-50 xl:static" onClick={() => setStartDay(dayjs(schedule.DisplayWorkDate))}>
                  <div className="flex-auto text-gray-900" tabIndex={-1} aria-labelledby='date at time at location at address'>
                    <Flexor justify='start' className='space-x-3'>
                      <CalendarDaysIcon className='h-5 w-5' aria-hidden/>
                      <h3 className="font-semibold xl:pr-0 space-x-2 flex items-center">
                        <div id='date'><DateFormatter dateString={schedule.DisplayWorkDate} withTime={false}/></div>
                        <div id='at'>at</div>
                        <div id='time'>{renderDataAttribute(schedule.StartTime)} until {renderDataAttribute(schedule.EndTime)}</div>
                      </h3>
                    </Flexor>
                    <dl className="pt-2 flex flex-col xl:flex-row">
                      <div className="flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5" aria-hidden>
                          <BuildingLibraryIcon className="h-5 w-5"/>
                        </dt>
                        <dd id='location'>
                          {renderDataAttribute(schedule.VotingLocationName)}
                        </dd>
                      </div>
                    </dl>
                    <dl className="pt-2 flex flex-col xl:flex-row">
                      <div className="flex items-start space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l-[0.5px] xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        <dt className="mt-0.5" aria-hidden>
                          <MapPinIcon className="h-5 w-5" aria-hidden="true"/>
                        </dt>
                        <dd id='address'>{renderDataAttribute(schedule.FullAddress)}</dd>
                      </div>
                    </dl>
                    <dl className="pt-2 flex flex-col xl:flex-row">
                      <div className="pt-0.5 ml-1 flex items-center space-x-3 xl:ml-3.5 xl:mt-0 xl:border-l-[0.5px] xl:border-gray-400 xl:border-opacity-50 xl:pl-3.5">
                        {getStatusLabel(schedule)}
                      </div>
                    </dl>
                  </div>
                  <Button className='ml-20' variant='primary' onClick={() => setShowWorkConfirmation(schedule)}>{schedule.ConfirmationStatus ? 'Change' : 'Respond'}</Button>
                </li>
              </Fragment>
            ))
          }
        </ol>
      </div>

      <div className='hidden lg:block w-3/5 pl-6'>
        <ScreenReaderOnly>Visual representation of work schedule in a calendar view</ScreenReaderOnly>
        <ScreenReaderHidden>
          <Calendar startDay={startDay} onChange={(date) => setSelectedDay(date)}/>
        </ScreenReaderHidden>
      </div>
    </div>
  )
}
