import React, {Fragment, ReactNode, useEffect, useState} from 'react';
import {XMarkIcon} from "@heroicons/react/24/outline";
import {Dialog, Transition } from '@headlessui/react';
import {SectionSubHeading} from "./SectionHeading";
import {classNames} from "../utils";
import Flexor from "./Flexor";

type ModalProps = {
  children: ReactNode;
  icon?: ReactNode;
  title?: string | ReactNode;
  onClose?: Function;
  open?: boolean;
  optionButtons?: boolean;
  iconColor?: string;
  squared?: boolean;
  onIsClosed?: () => void;
  size?: 'lg' | 'xl' | '2xl' | '3xl' | 'full';
  className?: string;
}

function getSize(size: string) {
  switch(size) {
    case 'lg':
      return 'max-w-lg';
    case '2xl':
      return 'max-w-2xl';
    case '3xl':
      return 'max-w-3xl';
    case 'full':
      return 'w-full h-screen';
    case 'xl':
    default:
      return 'max-w-xl';
  }
}

/*
 * @deprecated You should use <Dialog /> instead
 */
export default function DeprecatedModal({
  icon,
  title,
  children,
  open: show = false,
  size = 'xl',
  className,
  iconColor = 'bg-green-100',
  onClose = () => {},
  onIsClosed = () => {},
  squared = false,
}: ModalProps) {
  const [showChildren, setShowChildren] = useState<boolean>(show);

  useEffect(() => {
    if (show) setShowChildren(true);
  }, [show]);

  return (
    <Transition.Root afterLeave={() => onIsClosed()} show={show} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-30 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              afterLeave={() => setShowChildren(false)}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={classNames(squared ? '' : 'rounded-lg', "relative bg-white transform overflow-hidden px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6 w-full", getSize(size), className)}>
                <Dialog.Title as="div" className="w-full text-base font-semibold text-gray-900">
                  <Flexor>
                    <Flexor className="space-x-2">
                      {
                        icon && (
                          <div className={`mx-auto flex h-12 w-12 items-center justify-center rounded-full ${iconColor}`}>
                            {icon}
                          </div>
                        )
                      }
                      <SectionSubHeading>{title}</SectionSubHeading>
                    </Flexor>
                    <button data-testid='close-modal' onClick={() => onClose()} className="absolute top-6 right-6" aria-label='close'>
                      <XMarkIcon className="h-7 w-7" />
                    </button>
                  </Flexor>
                </Dialog.Title>
                <div className="mt-2 h-full">
                  {showChildren ? children : null}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
