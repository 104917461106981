import * as React from "react"
import * as DialogPrimitive from "@radix-ui/react-dialog"
import { cn } from "../../utils";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { HeadingSub } from "../SectionHeading";
import { cva, VariantProps } from "class-variance-authority";
import { twMerge } from "tailwind-merge";
import { createContext, useContext } from "react";
import { DialogProps } from "@radix-ui/react-dialog";
import { ScrollArea } from './ScrollArea';
import Flexor from "../Flexor";

const DialogRoot = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;

export const dialogAdornerVariants = cva(
  'absolute top-0 left-0 w-full bg-gradient-to-r h-2 border-b-[1px] border-gray-300',
  {
    variants: {
      variant: {
        default: 'from-action-gradient-from to-action-gradient-to',
        warning: 'from-warning-gradient-from to-warning-gradient-to',
        error: 'from-error-gradient-from to-error-gradient-to',
        success: 'from-success-gradient-from to-success-gradient-to',
      },
      size: {
        'sm': 'max-w-md',
        'md': 'max-w-xl',
        'lg': 'max-w-2xl',
        'full': 'w-full'
      },
    },
    defaultVariants: {
      variant: 'default',
    }
  }
);

export const dialogSizeVariants = cva(
  'fixed left-[50%] top-[50%] z-50 grid w-full p-6 translate-x-[-50%] translate-y-[-50%] gap-4 bg-white shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
  {
    variants: {
      size: {
        sm: 'max-w-md',
        md: 'max-w-lg',
        lg: 'max-w-2xl',
        full: 'w-full h-full flex flex-col'
      }
    },
    defaultVariants: {
      size: 'md',
    }
  }
)

export const dialogTitleVariants = cva(
  '[&>svg]:size-5 [&>svg]:stroke-2 lg:[&>svg]:size-8 [&>svg]:mr-3',
  {
    variants: {
      variant: {
        default: '[&>svg]:hidden ',
        success: 'text-success',
        error: 'text-error',
        warning: 'text-warning',
      }
    },
    defaultVariants: {
      variant: 'default'
    }
  }
);

const DialogOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    ref={ref}
    className={cn(
      "fixed inset-0 z-50 bg-black/60 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
));

DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;

export interface DialogContentProps extends React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content>, VariantProps<typeof dialogAdornerVariants> {
  container?: HTMLElement | null
}

const DialogContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  DialogContentProps
>(({ className, container, children, variant: directVariant, ...props }, ref) => {
  const { variant, size }: VariantProps<typeof dialogAdornerVariants> & VariantProps<typeof dialogSizeVariants> = useContext(DialogContext);

  return (
    <DialogPortal container={container}>
      <DialogOverlay />
      <DialogPrimitive.Content
        ref={ref}
        className={twMerge(cn(dialogSizeVariants({size, className})))}
        {...props}
      >
        <div className={twMerge(cn(dialogAdornerVariants({ variant })))} />
        {
          size === 'full' ? (
            children
          ) : (
            <ScrollArea className="max-h-dvh">
              {children}
            </ScrollArea>
          )
        }
        <DialogPrimitive.Close className="absolute right-4 top-6 text-action ring-action transition-opacity hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-callout data-[state=open]:text-neutral-400">
          <XMarkIcon className="size-5 stroke-2"/>
          <span className="sr-only">Close</span>
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPortal>
  );
});

DialogContent.displayName = DialogPrimitive.Content.displayName;

const DialogHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col space-y-1.5 text-center sm:text-left",
      className
    )}
    {...props}
  />
)
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      "flex flex-col-reverse border-t border-action pt-3 sm:flex-row sm:justify-end sm:space-x-2",
      className
    )}
    {...props}
  />
)
DialogFooter.displayName = "DialogFooter";

const DialogTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => {
  const { variant }: VariantProps<typeof dialogAdornerVariants> = useContext(DialogContext);

  return (
    <DialogPrimitive.Title
      ref={ref}
      asChild
      className={className}
      {...props}
    >
      <Flexor justify='start' className={twMerge(cn(dialogTitleVariants({ variant, className })))}>
        {props.children}
      </Flexor>
    </DialogPrimitive.Title>
  );
});
DialogTitle.displayName = DialogPrimitive.Title.displayName;

const DialogDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    ref={ref}
    asChild
    className={className}
    {...props}
  >
    <HeadingSub size={3}>{props.children}</HeadingSub>
  </DialogPrimitive.Description>
));
DialogDescription.displayName = DialogPrimitive.Description.displayName;

const DialogContext = createContext<VariantProps<typeof dialogAdornerVariants>>({ variant: 'default' });

const Dialog = ({ variant, size, ...props }: VariantProps<typeof dialogAdornerVariants> & DialogProps) => {
  return (
    <DialogContext.Provider value={{variant, size}}>
      <DialogRoot {...props} />
    </DialogContext.Provider>
  );
}

export {
  Dialog,
  DialogPortal,
  DialogOverlay,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
}
