import React, {useEffect, useState} from "react";
import {
  getDocumentFile,
  getListOfDocuments,
  uploadDocument,
} from "../fetchers";
import {
  ArrowUpOnSquareIcon, DocumentDuplicateIcon,
  DocumentIcon, PlusIcon, XCircleIcon
} from "@heroicons/react/24/outline";
import {useAppSelector} from "../hooks";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import { useDropArea } from "react-use";
import { classNames } from "shared/src/utils/classNames";
import { Flexor, Spinner } from "shared/src/components";
import { toast } from "react-toastify";
import DateFormatter from "shared/src/components/DateFormatter";
import { downloadDocument, getFileExtension } from "shared/src/utils/downloadDocument";
import PageEmptyState from "./PageEmptyState";
import {ScreenReaderOnly} from "shared/src/components/Accessibility";
import {LinkAnchor, LinkButton} from "shared/src/components/Links";
import { Button } from "shared/src/components/ui/Button";

const TEN_MEGABYTES = 10 * 1024 * 1024;

type Document = {
  DocumentId: string,
  DocumentName: string,
  DocumentUrl: string,
  UploadedBy: string,
  DateCreated: string,
}

export default function Documents() {
  const currentUser = useAppSelector((state) => state.currentPollworkerUser);
  const [showUploadDocument, setShowUploadDocument] = useState<boolean>(false);
  const [documentName, setDocumentName] = useState<string>('');
  const [documents, setDocuments] = useState<Document[]>([]);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [bond, { over: isDroppingFile }] = useDropArea({
    onFiles: (files) => {
      const file = files[0];
      updateFileToUpload(file);
    },
  });

  useEffect(() => {
    loadDocuments();
  }, [currentUser.Customer.Id]);

  useEffect(() => {
    if (!fileToUpload) return;

    setDocumentName(fileToUpload.name);
  }, [fileToUpload]);

  function loadDocuments() {
    setUploadingFile(false);
    setLoadingDocuments(true);
    getListOfDocuments(currentUser.Customer.Id).then((response) => {
      setDocuments(response);
    }).finally(() => {
      setLoadingDocuments(false);
    });
  }

  function updateFileToUpload(file: File) {
    if (file.size > TEN_MEGABYTES) return setErrorMessage('The file is too large.');

    setErrorMessage(undefined);
    setFileToUpload(file);
  }

  function teardownUploadModal() {
    setUploadingFile(false);
    removeFileToUpload();
  }

  function downloadDocumentFile(document: Document) {
    const {DocumentId: fileId, DocumentName: fileName, DocumentUrl: documentUrl} = document;

    getDocumentFile(fileId)
    .then(async (resp) => resp.ok ? await resp.blob() : undefined)
    .then(downloadDocument(fileName, getFileExtension(documentUrl)));
  }

  async function uploadDocumentFile() {
    if (!fileToUpload || !documentName) return;

    setUploadingFile(true);

    await uploadDocument(currentUser.EVUserId, currentUser.Customer.Id, documentName, fileToUpload).then(([success]) => {
      if (!success) return setErrorMessage('Something went wrong. Please try again.');

      setShowUploadDocument(false);
      toast.success('File has been uploaded!');
    }).finally(loadDocuments);
  }

  function removeFileToUpload() {
    setFileToUpload(undefined);
    setDocumentName('');
  }

  function renderAddDocumentButton() {
    return (
      <Button
        type="button"
        onClick={() => setShowUploadDocument(true)}
      >
        <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
        Add Document
      </Button>
    )
  }

  const uploadDisabled = uploadingFile || !fileToUpload || !documentName;

  return (
    <div className='mt-2'>
      <DeprecatedModal onIsClosed={teardownUploadModal} onClose={() => setShowUploadDocument(false)} size='lg' title='Add Document' open={showUploadDocument}>
        <div className='space-y-5'>
          {
            errorMessage ? (
              <div className="rounded-md bg-red-50 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <XCircleIcon className="h-5 w-5 text-red-400" aria-hidden="true" />
                  </div>
                  <div className="ml-3 text-sm text-red-700">
                    {errorMessage}
                  </div>
                </div>
              </div>
            ) : null
          }
          <div>
            <label htmlFor="file-upload" className="block text-sm font-medium leading-6 text-gray-900">
              File to upload
            </label>
            <div {...bond} className={classNames(isDroppingFile ? 'animate-pulse border-ev-blue' : 'border-gray-900/25', 'mt-2 rounded-lg border border-dashed')}>
              <div className='flex justify-center px-6 py-10'>
                <div className="text-center">
                  <ArrowUpOnSquareIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                  <div className="mt-4 flex text-sm leading-6 text-gray-600">
                    <label
                      htmlFor="file-upload"
                      className="relative cursor-pointer rounded-md bg-white font-semibold text-ev-blue focus-within:outline-none focus-within:ring-2 focus-within:ring-ev-blue focus-within:ring-offset-2 hover:text-ev-blue"
                    >
                      <LinkAnchor>{fileToUpload ? 'Replace file' : 'Upload a file'}</LinkAnchor>
                      <input onChange={({target: {files}}) => files && updateFileToUpload(files[0])} id="file-upload" name="file-upload" type="file" className="sr-only" />
                    </label>
                    <p className="pl-1">or drag and drop</p>
                  </div>
                  <p className="text-xs leading-5 text-gray-600">PDF, XLSX, CSV, PNG, JPG, GIF up to 10MB</p>
                </div>
              </div>
              {fileToUpload ? (
                <div className="m-2 border border-gray-300 rounded-lg text-sm p-2 leading-5 text-gray-600 flex items-center space-x-2">
                  <Flexor className='space-x-1 shrink text-wrap'>
                    <DocumentIcon className='h-5 w-5 shrink-0' />
                    <span className=''>{fileToUpload?.name}</span>
                  </Flexor>
                  <XCircleIcon data-testid='remove-file' className='h-5 w-5 shrink-0' onClick={removeFileToUpload} />
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <label htmlFor="document-name" className="block text-sm font-medium leading-6 text-gray-900">
              Name
            </label>
            <div className="mt-2">
              <input
                type="text"
                name="document-name"
                id="document-name"
                value={documentName}
                onChange={({target: {value}}) => setDocumentName(value)}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-ev-blue sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className='flex justify-end items-center'>
            <Button onClick={uploadDocumentFile} disabled={uploadDisabled}>
              <Flexor className='space-x-2'>
                <Spinner show={uploadingFile} />
                <span>Upload</span>
              </Flexor>
            </Button>
          </div>
        </div>
      </DeprecatedModal>
      <div>
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1
              className="text-base font-semibold leading-6 text-gray-900"
              role="heading"
            >Documents</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of all the documents in your account including their name, the date they were uploaded, and who they were uploaded by.
            </p>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            {renderAddDocumentButton()}
          </div>
        </div>
        {
          loadingDocuments && !documents.length ? (
            <div className='w-full flex justify-center items-center h-24 my-24 space-x-2'>
              <Spinner show />
              <span className='text-lg'>Loading documents...</span>
            </div>
          ) : null
        }
        {
          !loadingDocuments && !documents.length ? (
            <div className='my-10'>
              <PageEmptyState icon={DocumentDuplicateIcon} emptyMessageHeader='No documents' emptyMessageSubHeader='Get started by uploading a new document.'>
                <div className='mt-5'>{renderAddDocumentButton()}</div>
              </PageEmptyState>
            </div>
          ) : null
        }
        {
          !loadingDocuments && documents.length ? (
            <div className="-mx-4 mt-8 sm:-mx-0">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                <tr>
                  <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                    Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Date Created
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Uploaded By
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                    <ScreenReaderOnly>Download</ScreenReaderOnly>
                  </th>
                </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                {
                  documents.map((document) => (
                    <tr key={document.DocumentId}>
                      <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-0 truncate">
                        <LinkAnchor onClick={() => downloadDocumentFile(document)}>{document.DocumentName}</LinkAnchor>
                        <dl className="font-normal lg:hidden">
                          <dt className="sr-only sm:hidden">Date Created</dt>
                          <dd className="mt-1 truncate text-gray-500 sm:hidden"><DateFormatter dateString={document.DateCreated} withTime={false} /></dd>
                          <dt className="sr-only">Uploaded By</dt>
                          <dd className="mt-1 truncate text-gray-700 sm:hidden">{document.UploadedBy}</dd>
                        </dl>
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"><DateFormatter dateString={document.DateCreated} withTime={false} /></td>
                      <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">{document.UploadedBy}</td>
                      <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                        <LinkButton onClick={() => downloadDocumentFile(document)}>
                          Download
                        </LinkButton>
                      </td>
                    </tr>
                  ))
                }
                </tbody>
              </table>
            </div>
          ) : null
        }
      </div>
    </div>
  )
}
