import React, {useEffect, useMemo, useState} from "react";
import {DocumentTextIcon, QueueListIcon} from "@heroicons/react/24/outline";
import DeprecatedModal from "shared/src/components/DeprecatedModal";
import {classNames} from "shared/src/utils/classNames";
import {ClassSession} from "./ScheduledClasses";
import {getScheduledClassDetail} from "../fetchers";
import {DetailsPanel} from "./ModalScheduledClassDetailsPanel";
import {DocumentsPanel} from "./ModalScheduledClassDocumentsPanel";


type ManageScheduledClassProps = {
  scheduledClassId: string,
  showScheduleView: boolean,
  setShowScheduleView: (show: boolean) => void,
  setShowDropClassView: (show: boolean) => void,
};

export function ManageScheduledClass({scheduledClassId, showScheduleView, setShowScheduleView, setShowDropClassView}: ManageScheduledClassProps) {
  const [currentTab, setCurrentTab] = useState('Details');
  const [loading, setLoading] = useState<boolean>(true);
  const [classDetails, setClassDetails] = useState<ClassSession>();

  useEffect(() => {
    if (!scheduledClassId) return;

    getScheduledClassDetails(scheduledClassId).then((response) => {
      setClassDetails(response);
    }).finally(() => setLoading(false));
  }, [scheduledClassId]);

  const tabs = useMemo(() => ([
    {name: 'Details', current: currentTab === 'Details', icon: QueueListIcon},
    {name: 'Documents', current: currentTab === 'Documents', icon: DocumentTextIcon},
  ]), [currentTab]);

  return (
    <DeprecatedModal title={`${!loading ? classDetails?.ClassName : 'Class Session'}`} open={showScheduleView} onClose={() => setShowScheduleView(false)}>
      <div>
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  setCurrentTab(tab.name)
                }}
                className={classNames(
                  tab.current
                    ? 'border-ev-blue text-ev-blue'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'group inline-flex items-center border-b-2 py-4 px-1 text-sm font-medium'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.current ? 'text-ev-blue' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </button>
            ))}
          </nav>
        </div>
        <div>
          {currentTab === 'Details' ? <DetailsPanel scheduledClassId={scheduledClassId} classDetails={classDetails} setShowScheduleView={setShowScheduleView} setShowDropClassView={setShowDropClassView} /> : null}
          {currentTab === 'Documents' ? <DocumentsPanel classDetails={classDetails} /> : null}
        </div>
      </div>
    </DeprecatedModal>

  );
}

async function getScheduledClassDetails(scheduledClassId: string) {
  return await getScheduledClassDetail(scheduledClassId);
}
